import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { hooks } from "botframework-webchat";

import NormalSendBox from "./NormalSendBox";

import ComboBox from "./ComboBox";

import "./AutoComplete.css";

const DEFAULT_DESCRIPTION = "Please enter at least 3 characters to show options.";
const INITIAL_FILTERED_SUGGESTIONS = [];
const DEFAULT_CURSOR = -1;
const MIN_LENGTH = 3;

const AutoComplete = ({
  suggestions,
  enableAutoCompleteSendBox: enableAutoCompleteSendBoxVal,
  disableAutoCompleteSendBox,
  enableNormalSendBox: enableNormalSendBoxVal,
}) => {
  const { useSendMessageBack } = hooks;
  const sendMessageBack = useSendMessageBack();
  const inputRef = useRef();
  const [showOverlay, setShowOverlay] = useState(false);
  const [cursor, setCursor] = useState(DEFAULT_CURSOR);
  const [filteredSuggestions, setFilteredSuggestions] = useState(INITIAL_FILTERED_SUGGESTIONS);
  const [description, setDescription] = useState({ text: DEFAULT_DESCRIPTION, active: true });

  const resetOverlay = useCallback(() => {
    setFilteredSuggestions(INITIAL_FILTERED_SUGGESTIONS);
    setShowOverlay(false);
    setCursor(-1);
    inputRef.current.value = "";
  }, [setFilteredSuggestions, setShowOverlay]);

  const onClickHandler = useCallback(() => {
    if (!disableAutoCompleteSendBox.state) {
      setShowOverlay(true);
    }
  }, [setShowOverlay, disableAutoCompleteSendBox.state]);

  const keyboardNavigation = (event) => {
    const { code } = event;
    let suggestion = "";
    let cursorNewPos = -1;

    if (!showOverlay && code !== "Tab") {
      setShowOverlay(true);
    }

    switch (code) {
      case "ArrowDown":
        event.preventDefault();
        if (cursor !== -1 && cursor < filteredSuggestions.length - 1) {
          cursorNewPos = cursor + 1;
        } else if (cursor === filteredSuggestions.length - 1 || cursor === "GoBack") {
          cursorNewPos = "GoBack";
        } else {
          cursorNewPos = 0;
        }
        setCursor(cursorNewPos);
        break;

      case "ArrowUp":
        event.preventDefault();
        if (cursor === "GoBack") {
          cursorNewPos = filteredSuggestions.length - 1;
        } else if (cursor !== -1 && cursor > 0) {
          cursorNewPos = cursor - 1;
        } else {
          cursorNewPos = 0;
        }
        setCursor(cursorNewPos);
        break;

      case "Escape":
        resetOverlay();
        break;

      case "Home":
        setCursor(0);
        break;

      case "End":
        setCursor("GoBack");
        break;

      case "Enter":
        if (cursor !== -1 && cursor !== "GoBack") {
          suggestion = filteredSuggestions[cursor];
          sendMessageBack(suggestion.text, suggestion.text, suggestion.text);
          resetOverlay();
        } else if (cursor === "GoBack") {
          resetOverlay();
        }
        break;
      default:
    }
  };

  const selectSuggestion = useCallback(
    (event) => {
      // console.log(event)
      let suggestion = "";
      if (event.target.localName === "span") {
        suggestion = event.target.parentNode.innerText;
      } else {
        suggestion = event.target.innerText;
      }
      sendMessageBack(suggestion, suggestion, suggestion);
      resetOverlay();
    },
    [sendMessageBack, resetOverlay]
  );

  const selectSuggestionByKeyboard = useCallback(
    (event) => {
      const { code } = event;
      // console.log(event);
      // console.log(event.target.innerText);

      if (code === "Enter" || code === "Space") {
        event.preventDefault();
        const suggestion = event.target.innerText;
        sendMessageBack(suggestion, suggestion, suggestion);
        resetOverlay();
      }
    },
    [sendMessageBack, resetOverlay]
  );

  const filterSuggestions = useCallback(
    (txt) => {
      // console.log(txt);
      // console.log(suggestions);

      const TEXT_NOT_FOUND = -1;
      const subStrNotMatched = [];
      const txtLen = txt.length;
      let filterTxt = txt;
      let suggestion = "";
      const results = [];
      let indx = -1;

      // if (txt && txtLen >= 3 && !suggestions.stopWords.includes(txt.toLowerCase())) {
      if (txt && txtLen >= MIN_LENGTH) {
        setDescription({ text: DEFAULT_DESCRIPTION, active: false });
        setCursor(DEFAULT_CURSOR);
        const words = txt.trim().split(" ");
        // console.log(words);

        const tokens = words.filter((word) => !suggestions.stopWords.some((stopWord) => stopWord.startsWith(word)));
        // console.log(tokens);
        filterTxt = tokens.join(" ");
        const filterTxtLen = filterTxt.length;

        if (filterTxt) {
          for (let i = 0; i < suggestions.values.length; i += 1) {
            suggestion = suggestions.values[i];
            // console.log(filterTxt);
            // if (suggestion) {
            indx = suggestion.question.toLowerCase().indexOf(filterTxt.toLowerCase());
            // console.log(indx);
            if (indx !== TEXT_NOT_FOUND) {
              results.push({
                text: suggestion.question,
                hightLightTxtStartIndx: indx,
                highlightTxtLen: filterTxtLen,
                key: results.length.toString(),
                onClick: selectSuggestion,
                onKeyDown: selectSuggestionByKeyboard,
              });
            } else {
              subStrNotMatched.push(suggestion);
            }
            // }
          }
        }

        // for (let j = 0; j < subStrNotMatched.length; j++) {
        subStrNotMatched.forEach((unMatchedSuggestion) => {
          let keywordPresent = false;

          for (let i; i <= words.length; i += 1) {
            // if (unMatchedSuggestion.suggestion.keywords.includes(word.toLowerCase())) {
            // console.log(words[i]);
            if (unMatchedSuggestion.keywords.some((keyword) => keyword.includes(words[i].toLowerCase()))) {
              keywordPresent = true;
              break;
            }
          }

          if (keywordPresent) {
            results.push({
              text: unMatchedSuggestion.question,
              hightLightTxtStartIndx: -1,
              highlightTxtLen: -1,
              key: results.length.toString(),
              onClick: selectSuggestion,
              onKeyDown: selectSuggestionByKeyboard,
            });
          }
        });
      } else {
        setDescription({ text: `Please enter ${MIN_LENGTH - txtLen} more characters to show options.`, active: true });
      }

      return results;
    },
    [suggestions, selectSuggestion, selectSuggestionByKeyboard]
  );

  const showFilteredSuggestions = useCallback(
    (finalSuggestions = [], txt) => {
      const txtLen = txt.length;
      const count = finalSuggestions.length;

      if (count === 0 && txtLen >= MIN_LENGTH) {
        finalSuggestions.push({
          text: suggestions.fallBack,
          key: "0",
          onClick: selectSuggestion,
          onKeyDown: selectSuggestionByKeyboard,
        });
        // suggestions.push(<li onClick={selectSuggestion} key="help">help</li>);
      }

      // suggestions.push(<li onClick={resetOverlay} key='Go Back'>Go back</li>);
      setFilteredSuggestions(finalSuggestions);
      // return suggestions;
    },
    [setFilteredSuggestions, suggestions.fallBack, selectSuggestion, selectSuggestionByKeyboard]
  );

  const onChangeHandler = useCallback(
    (event) => {
      // console.log(event);
      // console.log(event.target.value);
      showFilteredSuggestions(filterSuggestions(event.target.value), event.target.value);
    },
    [showFilteredSuggestions, filterSuggestions]
  );

  const sendMessage = (event) => {
    const { key } = event;
    if (key === "Enter") {
      event.preventDefault();
      const msg = inputRef.current.value;
      inputRef.current.value = "";
      sendMessageBack(enableNormalSendBoxVal.value, msg, msg);
    }
  };

  const sendPayload = () => {
    sendMessageBack(
      enableNormalSendBoxVal.button.payload,
      enableNormalSendBoxVal.button.name,
      enableNormalSendBoxVal.button.name
    );
  };

  if (enableAutoCompleteSendBoxVal.state && !enableNormalSendBoxVal.state) {
    const defaultOptions = [{ text: "Go Back", key: "GoBack", onClick: resetOverlay, onKeyDown: resetOverlay }];

    return (
      <ComboBox
        isActive={showOverlay}
        description={description}
        inputRef={inputRef}
        options={filteredSuggestions}
        defaultOptions={defaultOptions}
        onClick={onClickHandler}
        cursor={cursor.toString()}
        disabled={disableAutoCompleteSendBox}
        onChange={onChangeHandler}
        onKeyDown={keyboardNavigation}
      />
    );
  }

  if (enableNormalSendBoxVal.state) {
    const extBtn = {};

    if (enableNormalSendBoxVal.button) {
      extBtn.name = enableNormalSendBoxVal.button.name;
      extBtn.onClick = sendPayload;
    }

    return <NormalSendBox inputRef={inputRef} onKeyDown={sendMessage} onBtnClick={sendPayload} extBtn={extBtn} />;
  }

  return "";
};

AutoComplete.propTypes = {
  suggestions: PropTypes.exact({
    values: PropTypes.array.isRequired,
    stopWords: PropTypes.array.isRequired,
    fallBack: PropTypes.string.isRequired,
  }).isRequired,
  enableAutoCompleteSendBox: PropTypes.exact({
    state: PropTypes.bool.isRequired,
  }).isRequired,
  disableAutoCompleteSendBox: PropTypes.exact({
    state: PropTypes.bool.isRequired,
    text: PropTypes.string,
  }).isRequired,
  enableNormalSendBox: PropTypes.shape({
    state: PropTypes.bool.isRequired,
    value: PropTypes.shape({
      action: PropTypes.string,
    }),
    button: PropTypes.shape({
      name: PropTypes.string.isRequired,
      payload: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

export default AutoComplete;
